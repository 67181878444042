import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import FileSaver from 'file-saver';
import { ChangeEvent, FC, useState } from 'react';
import {
  ConfirmDialog,
  ConfirmDialogOptions,
  EmptyTable,
  FilterTableMenu,
} from '../../../../../components';
import { CertificateStatusItem } from '../../../../../components/certificateStatus/CertificateStatusItem';
import { RejectedMessage } from '../../../../../components/RejectedMessage/RejectedMessage';
import { useLoading, useNotify } from '../../../../../hooks';
import { RequestProcessService } from '../../../../../services';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { DateHelper, getFileName } from '../../../../../utils/helpers';
import { SolicitudAlumno } from '../../../../../utils/models';
import { useCertificateHistory } from '../../hooks/useCertificateHistory';
import { CertificateHistoryTableActions } from '../CertificateHistoryTableActions/CertificateHistoryTableActions';
import { CertificateHistoryTableColumn } from '../enums/CertificateHistoryTableColumn';

interface Props {
  onReloadData: () => void;
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const CertificateHistoryTable: FC<Props> = ({
  onReloadData,
  onTableFilter,
}) => {
  const requestProcessService = new RequestProcessService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [showRequest, setShowRequest] = useState<SolicitudAlumno>(
    new SolicitudAlumno(),
  );

  const {
    certificateList,
    paginatorOptions,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    dir,
    setOrder,
  } = useCertificateHistory();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogOptions>({
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    setPage(value);
    onTableFilter(rowsPerPage, value, orderBy, dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    const valuePage = 1;
    setPage(valuePage);
    onTableFilter(value, valuePage, orderBy, dir);
  };

  const onOrderUpward = (columnName: CertificateHistoryTableColumn) => {
    setOrder(columnName, DirectionOptions.ASC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.ASC);
  };

  const onOrderFalling = (columnName: CertificateHistoryTableColumn) => {
    setOrder(columnName, DirectionOptions.DESC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.DESC);
  };

  const showRejectMessage = (request: SolicitudAlumno) => {
    setShowRequest(request);
  };

  const handleCloseRejectMessage = () => {
    setShowRequest(new SolicitudAlumno());
  };

  const sendToDownload = async (request: SolicitudAlumno) => {
    setLoading(true);
    await requestProcessService
      .getURLToDownloadCertificate(request.id)
      .then(async (r) => {
        const res = r.data;
        setLoading(false);
        if (res.success) {
          axios
            .get(res.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream',
              });
              const fileName = getFileName(request.nombre);
              const file = new File([blob], fileName, {
                type: 'application/pdf',
              });
              FileSaver.saveAs(file, fileName);
              if (!request.esDescargada) {
                window.open(requestProcessService.getURLToQuiz(), '_blank');
                onReloadData();
              }
            })
            .catch(() => {
              setNotify({
                ...notify,
                open: true,
                message: 'Error al obtener el archivo',
                type: 'error',
              });
            });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const sendToPay = (request: SolicitudAlumno) => {
    setLoading(true);
    window.location.replace(requestProcessService.getURLToPay(request.id));
  };

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <RejectedMessage
        request={showRequest}
        onClose={handleCloseRejectMessage}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                Nombre{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateHistoryTableColumn.NOMBRE)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateHistoryTableColumn.NOMBRE)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Fecha de solicitud{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateHistoryTableColumn.FECHA_CREACION)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateHistoryTableColumn.FECHA_CREACION)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Fecha de emisión{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateHistoryTableColumn.EMISION)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateHistoryTableColumn.EMISION)
                  }
                />
              </TableCell>
              <TableCell variant="head" sx={{ maxWidth: '100px' }}>
                Status{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateHistoryTableColumn.ESTATUS)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateHistoryTableColumn.ESTATUS)
                  }
                />
              </TableCell>
              <TableCell variant="head" align="center" sx={{ pt: 1 }}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificateList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>
                  {DateHelper.convertDateString(row.fechaCreacion)}
                </TableCell>
                <TableCell>
                  {DateHelper.convertDateString(row.fechaEmision)}
                </TableCell>
                <TableCell
                  className="table__cell"
                  sx={{ p: 1, minWidth: '230px' }}>
                  <CertificateStatusItem statusId={row.estatusId} />
                </TableCell>

                <TableCell align="center">
                  <CertificateHistoryTableActions
                    request={row}
                    showRejectMessage={() => showRejectMessage(row)}
                    onDownload={() => sendToDownload(row)}
                    pay={() => sendToPay(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable elements={certificateList} />
      <Divider />
      <TablePagination
        rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
        component="div"
        count={paginatorOptions.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        SelectProps={{
          inputProps: {
            'aria-label': 'Filas por página',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
