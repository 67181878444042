import { Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import FileSaver from 'file-saver';
import certificateDownloadIcon from '../../../../../assets/icons/certificateDownloadIcon.svg';
import { useLoading, useNotify } from '../../../../../hooks';
import { RequestProcessService } from '../../../../../services';
import { getFileName } from '../../../../../utils/helpers';
import { useRequestProcess } from '../../hooks/useRequestProcess';

export const DownloadCertificate = () => {
  const requestProcessService = new RequestProcessService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const { certificate, setCertificate } = useRequestProcess();

  const handleDownload = async () => {
    setLoading(true);
    await requestProcessService
      .getURLToDownloadCertificate(certificate.id)
      .then(async (r) => {
        const res = r.data;
        setLoading(false);
        if (res.success) {
          axios
            .get(res.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream',
              });

              const fileName = getFileName(certificate.nombre);
              const file = new File([blob], fileName, {
                type: 'application/pdf',
              });
              FileSaver.saveAs(file, fileName);
              if (!certificate.esDescargada) {
                window.open(requestProcessService.getURLToQuiz(), '_blank');
                certificate.esDescargada = true;
                setCertificate(certificate);
              }
            })
            .catch(() => {
              setNotify({
                ...notify,
                open: true,
                message: 'Error al obtener el archivo',
                type: 'error',
              });
            });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ bgcolor: 'primary.light', borderRadius: '16px' }}>
        <Grid container py={{ xs: 2, md: 6 }} px={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              variant="h1"
              color="secondary.dark"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '20px', md: '32px' },
                pt: 4,
                textAlign: { xs: 'center', md: 'left' },
              }}>
              ¡Descarga tu constancia electrónica!
            </Typography>
            <Grid item xs={12} md={8} sx={{ py: 4 }}>
              <Typography
                component="div"
                variant="subtitle2"
                sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                El formato electrónico de tu constancia esta listo, ¡Ya puedes
                descargarlo!
              </Typography>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              sx={{
                px: 3,
                py: 1,
                color: 'white',
                width: { xs: '100%', md: 'auto' },
              }}>
              Descargar Constancia
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
            }}>
            <div>
              <img src={certificateDownloadIcon} alt="icon" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
